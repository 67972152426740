import React from "react";

const ScheduleStandings = () => {
  return (
    <div>
      <h2>Welcome to the Schedule/Standings page</h2>
      <p>This is the content of the Schedule/Standings page.</p>
    </div>
  );
}

export default ScheduleStandings;