import React from "react";

const Divisions = () => {
  return (
    <div>
      <h2>Welcome to the About & Contact page</h2>
      <p>This is the content of the About & Contact page.</p>
    </div>
  );
}

export default Divisions;