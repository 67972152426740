import React from "react";

const Media = () => {
  return (
    <div>
      <h2>Welcome to the Media page</h2>
      <p>This is the content of the Media page.</p>
    </div>
  );
}

export default Media;