import React from "react";

const Divisions = () => {
  return (
    <div>
      <h2>Welcome to the Divisions page</h2>
      <p>This is the content of the Divisions page.</p>
      
    </div>
  );
}

export default Divisions;